.loader {
    opacity: 0;
    pointer-events: none;
    transition: all var(--anim-duration-short);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary);
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loader.active {
    opacity: 1;
}

.progress-bar {
    --progress-bar-size: 8px;
    overflow: hidden;
    border-radius: var(--progress-bar-size);
    border: 1px solid var(--color-reverse);
    width: 100%;
    max-width: 300px;
}

.progress-bar .progress {
    height: var(--progress-bar-size);
    background-color: var(--color-reverse);
    transition: all .5s var(--anim-easing);
}