#hero-wrapper {
    position: sticky;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh;
}

.hero-section {
    /* height: calc(100vh - var(--ticker-height)); */
    height: 100vh;
}

canvas {
    /* margin: 20px; */
}

#lofi-video {
    /* display: none; */
    position: absolute;
    opacity: 0;
    pointer-events: none;
}