header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    padding: var(--size-m);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    /* mix-blend-mode: difference; */
}

.nav-top,
.nav-bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}


.nav-link {
    flex-grow: 0;
    font-size: 3rem;
    letter-spacing: -0.1rem;
}

.nav-link:hover {
    mix-blend-mode: unset;
    text-decoration: underline;
}