:root {
    /* --ticker-height: var(--size-m); */
    --ticker-height: 50px;
}

.ticker-section {
    position: relative;
    height: var(--ticker-height);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: stretch;
    margin-top: calc(var(--ticker-height) * -1);
    overflow: hidden;
    color: var(--color-reverse);
    border-top: 1px solid var(--color-reverse);
    border-bottom: 1px solid var(--color-reverse);
}



.ticker-text-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    overflow: hidden;
}

.ticker-text {
    font-size: 0.7rem;
    box-sizing: content-box;
    position: relative;
    flex-basis: auto;
    flex-shrink: 0;
    flex-grow: 0;
    animation: ticker 30s linear 0s forwards infinite;
    will-change: transform;
    left: -100%;
}

@keyframes ticker {
    from {
        /* transform: translateX(0%); */
        left: -100%;
    }

    to {
        /* transform: translateX(-100%); */
        left: -200%;
    }
}

.ticker-icon {
    width: var(--ticker-height);
    padding-left: var(--size-xs);
    padding-right: var(--size-xs);
    flex-grow: 0;
    flex-shrink: 0;
    border-right: 1px solid var(--color-reverse);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ticker-section .icon {
    --icon-size: 22px;
    width: var(--icon-size);
    height: var(--icon-size);
}
