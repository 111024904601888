.about-section {
    padding: var(--size-m);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-text {
    text-align: justify;
    font-size: 9vw;
    letter-spacing: -0.35vw;
    line-height: 1.1;
}

.about-text a {
    text-decoration: underline;
    text-decoration-thickness: 8px;
    text-decoration-color: #e77e33;
    transition: all var(--anim-duration-short) ease-out;
}

.about-text a:hover {
    color: #e77e33;
}